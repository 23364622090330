import * as React from "react"
import useStores from "../hooks/use-stores"

// markup
const Title = ({ pageName }) => {
  return (
    <title>{pageName} | Auokka</title>
  )
}

export default Title
